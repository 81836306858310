import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { User } from '../../interfaces/order.interface';

@Component({
  selector: 'ecommerce-footer',
  standalone: true,
  imports: [CommonModule, TranslocoModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  loggedIn!: boolean;
  user?: User;

  ngOnInit(): void {
    this.loggedIn = !!localStorage.getItem('token');
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(<string>localStorage.getItem('user'));
    }
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
